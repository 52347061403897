import * as Sentry from "@sentry/browser";
import * as React from 'react';
import { createContext, useEffect, useState } from 'react';

import { getPayloadByJwt } from "@/lib/get-payload-jwt";

import { getToken } from "@/services/storage";

type ProfileType = {
  email?: string;
  id?: number;
  investProfileId?: string;
}

export type ProfileContextType = {
  profile: ProfileType | null,
  setProfile: (profile: ProfileType) => void,
}

interface Props {
  children: React.ReactNode;
}

export const ProfileContext = createContext<ProfileContextType>({
  profile: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setProfile: () => { },
});

const PROFILE_KEY_STORAGE = 'profile';

const ProfileProvider: React.FC<Props> = ({ children }: Props) => {
  const [profile, _setProfile] = useState<ProfileType | null>(null);

  const initialLoad = () => {
    if (typeof window === 'undefined') {
      return;
    };

    const _profile = localStorage.getItem(PROFILE_KEY_STORAGE);

    if (!_profile) {
      return;
    }

    const localValue = JSON.parse(_profile);
    const localProfile = (
      localValue as unknown as ProfileType
    ) ?? null;

    _setProfile(localProfile);
  };

  useEffect(initialLoad, []);

  const setProfile = (currentProfile: ProfileType) => {

    if (!currentProfile.email || !currentProfile.id) {
      const token = getToken();

      if (token) {
        const { email, id } = getPayloadByJwt(token.toString());
        currentProfile.email = email;
        currentProfile.id = id;
      }
    }

    if (currentProfile.email) {
      Sentry.setUser({
        email: currentProfile.email,
      });
    }

    _setProfile(currentProfile);
    localStorage.setItem(PROFILE_KEY_STORAGE, JSON.stringify(currentProfile));
  };

  return (
    <ProfileContext.Provider value={{ profile, setProfile }}>
      {children}
    </ProfileContext.Provider>
  );
}

export default ProfileProvider;
