/* eslint-disable no-console */
/* eslint-disable @next/next/inline-script-id */
/* eslint-disable @typescript-eslint/no-empty-function */
import * as Sentry from '@sentry/nextjs';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from "next/script";
import { useEffect } from 'react';
import { hotjar } from 'react-hotjar';

import '@/styles/globals.css';
import '@/styles/colors.css';

import ProfileProvider from '@/providers/profile';

import * as gtag from "../lib/gtag";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: process.env.NEXT_PUBLIC_ENV !== 'local'
        ? Sentry.captureException
        : console.error,
      cacheTime: 60 * 60 * 5,
    },
    mutations: {
      onError: (error: unknown) => {
        if (process.env.NEXT_PUBLIC_ENV !== 'local') {
          Sentry.captureException(error);
        }
      },
    }
  }
});

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (process.env.NEXT_PUBLIC_ENV === 'production') {
        gtag.pageview(url);
      }
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);


  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENV === 'production') {
      hotjar.initialize(3108450, 6);
    }
  }, []);

  return (

    <>
      {process.env.NEXT_PUBLIC_ENV === 'production' && (
        <>
          <Script
            strategy="afterInteractive"
            src="https://www.googletagmanager.com/gtag/js?id=G-T1684KNW67"
          />
          <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-T1684KNW67', {
                page_path: window.location.pathname,
              });
            `,
            }}
          />
        </>
      )}

      <ProfileProvider>
        <QueryClientProvider client={queryClient}>
          <Component {...pageProps} />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ProfileProvider>
    </>
  );
}

export default MyApp;
