export const ZUMMA_COOKIE_NAME = 'zumma-token';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const saveToken = async (promise: Promise<any>) => {
  const response = await promise;
  const { token } = response.data;
  localStorage.setItem(ZUMMA_COOKIE_NAME, token);

  return response;
}

export const saveValidateTokenMail = (token: string) => {
  localStorage.setItem(ZUMMA_COOKIE_NAME, token);
}

export const removeToken = () => {
  localStorage.removeItem(ZUMMA_COOKIE_NAME);
}

export const getToken = () => {
    return localStorage.getItem(ZUMMA_COOKIE_NAME);
}
